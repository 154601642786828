/**
 * Strip HTML from a string and decode HTML entities
 * @param {string} string - The string to strip HTML from
 * @returns {string} The string with HTML stripped
 */

function decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}

export default string => {
    if (typeof document === 'undefined') return string.replace(/<\/?[^>]+>/ig, '');
    const decodedString = decodeHTMLEntities(string);
    return decodedString.replace(/<\/?[^>]+>/ig, '');
};
